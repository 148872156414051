var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return Object.keys(_vm.item).length
    ? _c(
        "layout",
        { attrs: { full: "" } },
        [
          _c("div", { staticClass: "header" }, [
            _c("img", {
              attrs: {
                src: require("../../../assets/img/album/nav_icon_back.svg"),
                alt: "",
              },
              on: { click: _vm.close },
            }),
          ]),
          _c("content-item", {
            staticClass: "item",
            attrs: { item: _vm.item },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }