<template>
  <layout full v-if="Object.keys(item).length">
    <div class="header">
      <img src="../../../assets/img/album/nav_icon_back.svg" alt="" @click="close">
    </div>
    <content-item :item="item" class="item"></content-item>
  </layout>
</template>

<script>
import {getOneBuzz} from 'api/api'
import ContentItem from 'views/index/component/ContentItem'

export default {
  name: "SquareDetail",
  data() {
    return {
      item: {},
      show: false,
      metaId: ''
    }
  },
  components: {
    ContentItem
  },
  mounted() {
    getOneBuzz({
      txId: this.$route.query.buzzTxId
    }).then(res => {
      this.item = res.data.results.items[0]
      let followData = localStorage.getItem('followData')
      this.$set(this.item, 'isFollow', followData ? (JSON.parse(followData).followingList ? JSON.parse(followData).followingList : []).includes(this.item.metaId) : false)
    })
  },
  methods: {
    close() {
      this.$router.back()
    },
    openMorePopup(metaId) {
      this.metaId = metaId
      this.show = true
    },
    likeTa(item) {},
    followTa(item) {},
  },
  filters: {
    fileUrl(tx) {
      if (tx.indexOf("metafile://") != -1) {
        return `${process.env.VUE_APP_AppImgApi}/metafile/${tx.replace("metafile://", "compress640/").toLowerCase()}`
      } else {
        return `${process.env.VUE_APP_AppImgApi}/metafile/compress640/${tx}`
      }
    },
    avatarUrl(avatarTx) {
      if (avatarTx) {
        return `${process.env.VUE_APP_AppImgApi}/metafile/avatar/${avatarTx}`
      } else {
        return `${process.env.VUE_APP_AppImgApi}/metafile/avatar/undefined`
      }
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  height: 48px;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  img {
    width: 16px;
    height: 16px;
  }
}
.item {
  height: calc(100% - 48px);
  //height: calc(100vh - 48px);
  overflow: auto;
}
</style>